import React from 'react';

type PageFooterComponentProps = {
	children?: React.ReactNode
}

const PageFooterComponent: React.FC<PageFooterComponentProps> = props => {
	return(
    <div className="layout-footer">
        <dl className="info">
            <div className="item socials">
                <dt>Socials</dt>
                <dd>
                    <a href="https://www.instagram.com/sojeonseolim/" target="_blank">Instagram</a><br />
                </dd>
            </div>
            <div className="item">
                <dt>Contact</dt>
                <dd>
                    +82 (02) 542 0804<br/>
                    <a href="mailto:info@sojeonseolim.com">info@sojeonseolim.com</a>
                </dd>
            </div>
            <div className="item address">
                <dt>Address</dt>
                <dd><a href="https://goo.gl/maps/WXxc8nRNQGnmBTiX8" target="_blank">서울특별시 강남구<br />영동대로 138길 23</a></dd>
            </div>
        </dl>
        <small className="copyright">© Sojeonseolim</small>
    </div>
	)
}

export default PageFooterComponent;