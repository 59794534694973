import React, { useState, useEffect, useRef } from 'react';
import { clientFirebase } from '../../constants';
import moment from 'moment';

import ToggleContainer from './ToggleContainer';
import { LoadingComponent } from '../../components';


function HomeContainer() {
    const [openFlags, setOpenFlags] = useState([
        {
            uid: "sojeonseolim",
            open: false
        },
        {
            uid: "yedam",
            open: false
        },
        {
            uid: "chungdam",
            open: false
        },
        {
            uid: "haojae",
            open: false
        },
        {
            uid: "2x2",
            open: false
        },
        {
            uid: "membership",
            open: false
        }
    ]);

    const [filter, setFilter] = useState("upcoming");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    // mount
    useEffect(() => {
        setLoading(true);
        fetchData(filter);
    }, []);


    const fetchData = (filter:string) => {
        const db = clientFirebase.firestore();

        const current = moment(new Date()).toDate();
        let docRef = db.collection("events").orderBy("endDate").limit(10);
        if( filter === "upcoming" ) {
            docRef = db.collection("events").orderBy("endDate").where('endDate', '>=', current).limit(10);
        } else if( filter === "past" ) {
            docRef = db.collection("events").orderBy("endDate", "desc").where('endDate', '<', current).limit(10);
        }

        docRef.get().then((querySnapshot) => {
            let results = [] as any;
            let ongoing = [] as any;

            querySnapshot.forEach((doc) => {
                const item = doc.data();

                if( filter === "upcoming" ) {
                    if( item.startDate.seconds === item.endDate.seconds ) {
                        results.push({
                            uid: doc.id,
                            ...item
                        });
                    } else {
                        ongoing.push({
                            uid: doc.id,
                            ...item
                        });
                    }
                } else {
                    // past
                    results.push({
                        uid: doc.id,
                        ...item
                    });
                }
            });

            if( results.length > 5 - ongoing.length ) {
                results.length = 5 - ongoing.length;
            }
            
            setItems(results.concat(ongoing));
            setLoading(false);
        }).catch(function(error) {
            console.error("Error adding document: ", error);
            setLoading(false);
            setError(error);
        });
    };

    const handleFilter = (event:React.ChangeEvent<HTMLSelectElement>) => {
        setFilter(event.target.value);
        setLoading(true);
        fetchData(event.target.value);
    }


    const handleToogleMenus = (target:string, value:boolean) => {
        let results = [...openFlags];
        openFlags.map((item:any, index:number) => {
            if( item.uid === target ) {
                results[index] = {
                    ...item,
                    open: value
                }
            } else {
                results[index] = {
                    ...item,
                    open: false
                }
            }
        });

        setOpenFlags(results);
    }

    return(
        <div className="layout-contents-container">
            
            <section className="calendar">
                <h2 className="hidden">이벤트 목록</h2>

                <table className="tbl-events">
                    <caption>이벤트 목록</caption>
                    <colgroup>
                        <col style={{ width: "134px" }} />
                        <col style={{ width: "193px" }} />
                        <col/>
                        <col style={{ width: "70px" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Calendar</th>
                            <td>
                                <select name="filter" id="filter" className="select" defaultValue={filter} onChange={handleFilter}>
                                    <option value="upcoming">Upcoming Events</option>
                                    <option value="past">Past Events</option>
                                </select>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        { loading && (<tr><td colSpan={4}><LoadingComponent /></td></tr>)}
                        { items.length === 0 && !loading && <tr><td></td><td colSpan={3}>등록된 이벤트가 없습니다.</td></tr> }
                        { items && !loading && items.map((item:any, index:number) => {
                            const current = moment().unix();
                            let state = "Past Event";
                            if( current < item.startDate.seconds ) {
                                state = "Upcoming Event";
                            } else if( item.startDate.seconds < current && current < item.endDate.seconds ) {
                                state = "Ongoing Event";
                            }

                            let duration = (<><time dateTime={item.startDate.seconds}>{moment.unix(item.startDate.seconds).format("ddd, MMM, D, h:mma")}</time></>);
                            if( item.startDate.seconds !== item.endDate.seconds ) {
                                duration = (<>
                                    <time dateTime={item.startDate.seconds}>{moment.unix(item.startDate.seconds).format("ddd, MMM, D, YYYY")}</time>
                                    &nbsp;-<br />
                                    <time dateTime={item.endDate.seconds}>{moment.unix(item.endDate.seconds).format("ddd, MMM, D, YYYY")}</time>
                                    </>
                                )
                            }

                            const content = item.content.split('\n').map((txt:string, index:number) => {
                                return <>{txt}<br /></>
                            });
                        

                            let url = (<></>);
                            if( item.linkType === "public" ) {
                                url = <a href={item.link} target="_blank" className="btn-external">Register</a>
                            } else if( item.linkType === "ticket" ) {
                                url = <a href={item.link} target="_blank" className="btn-external">Ticket</a>
                            }


                            return(
                                <tr key={index}>
                                    <td></td>
                                    <td>
                                        {duration}<br />
                                        <span className="location">{item.location}</span>
                                    </td>
                                    <th>
                                        <p className="contents">
                                            {content}
                                        </p>
                                        <p className="host">by {item.host}</p>
                                    </th>
                                    <td>
                                        {url}
                                    </td>
                                </tr>
                            )})
                        }
                    </tbody>
                </table>
            </section>


            { openFlags && openFlags.map((item:any, index:number) => {
                return(<ToggleContainer key={index} target={item.uid} isOpen={item.open} targetClick={(uid, value) => {
                    handleToogleMenus(uid, value);
                }} />);
            })}

        </div>
    );
}

export default HomeContainer;

