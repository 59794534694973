import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { HashLink } from 'react-router-hash-link';

type ToggleProps = {
    target: string;
    isOpen: boolean;
    targetClick: (uid: string, flag: boolean) => void;
};

  
function ToggleContainer({ target, isOpen, targetClick }: ToggleProps) {
    const handleClick = () => targetClick(target, !isOpen);

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    let contents = <></>;
    switch(target) {
        case "sojeonseolim":
            contents = (
                <>
                    <Slider className="list-photo" {...settings}>
                        <img src="/assets/images/sojeonseolim_01@2x.png" alt="소전서림 이미지" />
                        <img src="/assets/images/sojeonseolim_02@2x.png" alt="소전서림 이미지" />
                    </Slider>

                    <h3>당신이 자라는 책의 숲, 소전서림</h3>
                    <br />
                    소전서림素磚書林은 ‘흰 벽돌로 둘러싸인 책의 숲’이다.<br />
                    <br />
                    소전서림素磚書林은 스스로 생성하며 순환하는 숲처럼, 책을 통한 독서와 문화의 경험이 자기 자신을 성장시키고, 쉬게 하며, 순환하게 한다고 믿는다. 소전서림素磚書林이 제시하는 문학, 예술, 철학 등의 인문학적 읽기는 취향을 고취하고, 교양을 갖추는 길을 제시할 것이다.<br />
                    <br />
                    <h3>Self-education × Life-blending</h3>
                    <br />
                    소전서림은 책을 통해 스스로 지성과 교육을 기르는 Self-education 공간이다. 성장하는 개인들의 건강하고 고요한 삶이 교차되는 이곳에서 새로운 일상이 탄생한다. 동시대를 이끄는 각계 명사들과의 만남은 공간과 커뮤니티에 사색과 공감의 깊이를 더한다. 소전서림이 제안하는 새로운 북큐레이션을 따라 가다보면, 삶을 바라보는 관점 또한 새롭게 블랜딩Life-blending 될 것이다.<br />
                    <br />
                    <h3>Library × Art Salon</h3>
                    <br />
                    소전서림은 도서관이자 아트살롱이다. 다른 이용객과 함께 책에 대한 토론과 대화를 즐길 수 있다. 상주하는 북큐레이터가 도서 추천 서비스를 제공하며, 예약 없이 이용할 수 있는 1인용 서가에서 행복한 고립을 즐길 수 있다. 또한 전 세계 갤러리와 미술관에서 출판한 도록, 예술 서적으로 채워진 예담藝談에서는 문학 및 인문학 강연, 공연, 낭독회가 열려 살아있는 예술을 나눈다.<br />
                    <br />
                    * 화요일~토요일 11시~23시. 일요일 9시30분~18시. 월요일 휴무.<br />
                </>
            )
        break;

        case "yedam":
            contents = (
                <>
                    <Slider className="list-photo" {...settings}>
                        <img src="/assets/images/yedam_01@2x.png" alt="예담 이미지" />
                        <img src="/assets/images/yedam_02@2x.png" alt="예담 이미지" />
                    </Slider>

                    <h3>예담 藝談</h3>
                    <br />
                    ‘예술 이야기를 나누는 공간’이라는 의미의 예담은 전 세계 주요 갤러리와 미술관에서 출판한 도록을 소개 하는 아트나우를 중심으로 미술, 건축, 음악, 영화, 사진 등 예술 인문학 서적들이 비치된 공간이다. 또한 캣워크로 연결되는 2층 서가에는 역사 및 과학 서적을, ‘읽기, 쓰기, 먹다, 마시다, 여행하다’를 테마로 구성한 테마 도서를 비치했다.<br />
                    <br />
                    예담은 소전서림 자체 기획 인문학 강좌 ‘소전아카데미’ 의 강의실이기도 하여, ‘문학더함’, ‘예술더함’, ‘철학더함’ 수업을 진행하고 있다. 종종 음악 콘서트, 특별 강연회, 갤러리 토크 등 다양한 이벤트를 열거나 대관하는 공간이기도 하다.<br />
                </>
            )
        break;
        
        case "chungdam":
            contents = (
                <>
                    <Slider className="list-photo" {...settings}>
                        <img src="/assets/images/chungdam_01@2x.png" alt="청담 이미지" />
                    </Slider>

                    <h3>청담 靑談</h3>
                    <br />
                    소전서림이 자리한 청담동의 ‘청담靑潭’은 맑은 못이라는 의미로, 조선시대에 ‘맑은 못이 있던 자리’라 하여 이름 붙여졌다. 소전서림에는 맑은 못을 대신하여 맑은 이야기를 나누는 청담靑談이 있다. 이곳은 소전서림이 초청한 문인을 위한 공간이며, 예담에서의 공연을 앞둔 아티스트를 위한 대기장소로 쓰인다.
                </>
            )
        break;
        
        case "haojae":
            contents = (
                <>
                    <Slider className="list-photo" {...settings}>
                        <img src="/assets/images/haojae_01@2x.png" alt="하오재 이미지" />
                        <img src="/assets/images/haojae_02@2x.png" alt="하오재 이미지" />
                        <img src="/assets/images/haojae_03@2x.png" alt="하오재 이미지" />
                    </Slider>

                    <h3>하오재 何오齋</h3>
                    <br />
                    지금으로부터 약 백여 년 전, 춘원 이광수는 물었다. “문학이란 하何오?” 이는 문학이란 무엇이냐는 뜻으로 매일신보에 실린, 한국 문학사 최초의 근대적 문학 개념을 정의한 문학론으로 평가된다. 백여 년 전 춘원의 질문으로부터 한국 근대 문학의 여러 담론들이 생겨난 것처럼, 책과 문학, 예술에 관한 다양한 층위의 논의가 오가는 하오재의 풍경을 상상한다. 계절에 따라 전면 창을 개방할 수 있는 하오재는 소전서림 자체 클래스 뿐만 아니라, 소규모의 대관, 투바이투의 손님들을 위한 공간으로 열려있다.
                </>
            )
        break;
        
        case "2x2":
            contents = (
                <>
                    <Slider className="list-photo" {...settings}>
                        <img src="/assets/images/twobytwo_01@2x.png" alt="2x2 이미지" />
                        <img src="/assets/images/twobytwo_02@2x.png" alt="2x2 이미지" />
                    </Slider>

                    <h3>투바이투 2x2</h3>
                    <br />
                    카페이자 와인 바인 투바이투2X2는 도스토옙스키가 「죄와 벌」, 「지하생활자의 수기」 등의 작품 에서 자주 사용한 관용구로, 2X2가 4인 것처럼 ‘틀림 없이 명확하다’는 의미로 사용됐다. 하지만 문학과 예술에서, 그리고 우리 인생에서 ‘2X2는 반드시 4여야만 하는 것일까?’라는 질문으로 투바이투는 문을 열었다. 투바이투는 오후 여섯 시까지 카페로 운영되며, 이후 와인바로 변신한다. 커피와 술, 음악과 예술 그리고 문학이 떼려야 뗄 수 없는 것처럼, 도서관과 카페, 바를 이용하는 사람들의 삶이 소전서림과 투바이투의 낮과 밤을 함께 할 수 있는 공간이다.<br />
                    <br />
                    <strong>카페</strong> 화~일 10시~18시, 월요일 휴무<br />
                    <strong>와인 바</strong> 월~일 18시~01시
                </>
            )
        break;
        
        case "membership":
            contents = (
                <>
                    <h3>소전서림 멤버십</h3>
                    <br />
                    소전서림 멤버를 위한 다채로운 혜택을 소개합니다. 소전서림의 멤버가 되시면, 예술, 철학, 문학을 기반으로 구성되는 인문학 강좌, 음악, 낭독, 강연 등의 이벤트, 워크숍 등의 프로그램을 할인된 가격으로 우선 등록하실 수 있습니다.
                    
                    <table className="tbl-membership-m">
                        <caption>멤버쉽 정보(모바일)</caption>
                        <tbody>
                            <tr>
                                <th colSpan={2} scope="col">일반회원</th>
                            </tr>
                            <tr>
                                <th scope="row">기준</th>
                                <td>일일 방문객</td>
                            </tr>
                            <tr>
                                <th scope="row">이용금액</th>
                                <td scope="row">반일권 3만원, 종일권 5만원<br />(이용시간 내 공연, 강연료 포함가)</td>
                            </tr>
                            <tr>
                                <th scope="row">혜택</th>
                                <td>북큐레이터의 도서 추천</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th colSpan={2} scope="col">소전회원</th>
                            </tr>
                            <tr>
                                <th scope="row">기준</th>
                                <td>연간회원 가입자</td>
                            </tr>
                            <tr>
                                <th scope="row">이용금액</th>
                                <td scope="row">반일권 1.5만원, 종일권 2.5만원</td>
                            </tr>
                            <tr>
                                <th scope="row">혜택</th>
                                <td>
                                    도서관 이용금액 할인 50%<br />
                                    소전아카데미, 이벤트(전시, 공연 등)<br />
                                    50% 할인 및 우선 예약권 제공<br />
                                    투바이투 음료 5% 할인 + 5% 적립
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th colSpan={2} scope="col">특별회원</th>
                            </tr>
                            <tr>
                                <th scope="row">기준</th>
                                <td>소전서림 위촉 문인</td>
                            </tr>
                            <tr>
                                <th scope="row">이용금액</th>
                                <td scope="row">자유 이용</td>
                            </tr>
                            <tr>
                                <th scope="row">혜택</th>
                                <td>
                                    전용공간 청담(淸談) 이용<br />
                                    <br />
                                    *만 20세 이상 입장 가능
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="tbl-membership">
                        <caption>멤버쉽 정보</caption>
                        <thead>
                            <tr>
                                <th style={{ minWidth: "60px" }}><span>구분</span></th>
                                <th>일반</th>
                                <th>소전회원</th>
                                <th>특별회원</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>기준</td>
                                <td>일일방문객</td>
                                <td>연간회원 가입자</td>
                                <td>소전서림 위촉 문인</td>
                            </tr>
                            <tr>
                                <td>이용 금액</td>
                                <td>
                                    반일권 3만원<br />
                                    <br />
                                    종일권 5만원
                                </td>
                                <td>
                                    반일권 1.5만원<br />
                                    <br />
                                    종일권 2.5만원
                                </td>
                                <td>자유</td>
                            </tr>
                            <tr>
                                <td>혜택</td>
                                <td>북큐레이터의 도서추천</td>
                                <td>
                                    도서관 이용금액 할인 50%<br />
                                    <br />
                                    소전아카데미, 이벤트(전시, 공연 등) 50% 할인 및 우선 예약권 제공<br />
                                    <br />
                                    투바이투 음료 5% 할인 + 5% 적립
                                </td>
                                <td>전용공간 청담(淸談) 이용</td>
                            </tr>
                        </tbody>
                    </table>

                    <a href="https://docs.google.com/forms/d/1i-j4Wf_ZvrENXbRl1N-GGr8WZZDhbvK3IO1zyOyHskU/viewform?edit_requested=true" target="_blank" className="btn-membership">멤버십 가입 신청</a>
                </>
            )
        break;
    }

    return(
        <section id={target} className="menus">
            <h2><HashLink to={`/#${target}`} smooth className="btn-toggle" onClick={handleClick}>{target}</HashLink></h2>
            <div className="expand-container" data-expand={isOpen}>
                {contents}
            </div>
        </section>
    );
}

ToggleContainer.defaultProps = {
    isOpen: false
};

export default ToggleContainer;

