import firebase from 'firebase';

export const AUTH_TOKEN = 'auth-token-plus';
export const isDev = process.env.NODE_ENV === "development";

var firebaseConfig = {}
if( isDev ) {
    firebaseConfig = {
        apiKey: "AIzaSyD9P58gdcXKKMKqapISptsCPABuArEumSc",
        authDomain: "sojeonseolim-6ab4d.firebaseapp.com",
        databaseURL: "https://sojeonseolim-6ab4d.firebaseio.com",
        projectId: "sojeonseolim-6ab4d",
        storageBucket: "sojeonseolim-6ab4d.appspot.com",
        messagingSenderId: "642631732415",
        appId: "1:642631732415:web:72ec32cd960fda7dab4a0a",
        measurementId: "G-EVY590LLLN"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyCvlFg4eR3MjQpnmL_xo0pGbNtEMsusxaY",
        authDomain: "sojeonseolim-7a26e.firebaseapp.com",
        databaseURL: "https://sojeonseolim-7a26e.firebaseio.com",
        projectId: "sojeonseolim-7a26e",
        storageBucket: "sojeonseolim-7a26e.appspot.com",
        messagingSenderId: "351232197133",
        appId: "1:351232197133:web:7cd0effb6e5db47b8c0b44",
        measurementId: "G-WGFKZWQCBS"
    };
}
firebase.initializeApp(firebaseConfig);

export const clientFirebase = firebase;

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const removeToken = () => localStorage.removeItem(AUTH_TOKEN);



